import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Stack, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import ManagementPlanHeadCount from "../../components/ConsignmentCreation/ManagementPlanHeadCount";
import ConsignmentProgression from "../../components/ConsignmentCreation/ConsignmentProgression";
import LoggedInContainer from "../../layout/LoggedInContainer";
import {  getManagementPlans, getSpecies, getSpeciesClasses } from "../../api/apiAccess";
import { addToOrUpdateSessionConsignmentBeingCreated, getSessionConsignmentBeingCreated } from "../../helpers/SessionHelper";


const Consignment_ManagementPlans = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [consignment, setConsignment] = useState(null);
    const [assignedPlans, setAssignedPlans] = useState([]);
    const [speciesOptions, setSpeciesOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [speciesClassOptions, setSpeciesClassOptions] = useState([]);
    const [showError, setShowError] = useState(false);
    const [managementPlanOptions, setManagementPlanOptions] = useState([]);

    useEffect(() => {
        let fetchManagementPlans = async () => {
            let results = await getManagementPlans();
            if (results?.status === 200) {
                setManagementPlanOptions(results.data);
            }
        }

        let fetchSpecies = async (consignmentBeingCreated) => {
            let results = await getSpecies();
            if (results?.status === 200) {
                let currentSpeciesIds = consignmentBeingCreated.livestock.map(ls => Number(ls.speciesId));
                let filteredSpecies = results.data.filter(sp => currentSpeciesIds.includes(Number(sp.id)));
                setSpeciesOptions(filteredSpecies);
            }
        }

        let fetchClasses = async (consignmentBeingCreated) => {
            let results = await getSpeciesClasses();
            if (results?.status === 200) {
                let currentClassIds = consignmentBeingCreated.livestock.map(ls => Number(ls.classId));
                let filteredClasses = results.data.filter(cl => currentClassIds.includes(Number(cl.id)));
                setClassOptions(filteredClasses);
            }
        }

        let populateSpeciesClassesOptions = (consignmentBeingCreated) => {
            if (!consignmentBeingCreated.livestock) return;
            let availableSpeciesAndClasses = consignmentBeingCreated.livestock.map(ls => `${ls.speciesId},${ls.classId}`);
            setSpeciesClassOptions(availableSpeciesAndClasses);
        }

        let fetchAll = async (consignmentBeingCreated) => {
            await fetchManagementPlans();
            await fetchSpecies(consignmentBeingCreated);
            await fetchClasses(consignmentBeingCreated);
        }

        let consignmentBeingCreated = getSessionConsignmentBeingCreated();

        setConsignment(consignmentBeingCreated);
        fetchAll(consignmentBeingCreated);
        populateSpeciesClassesOptions(consignmentBeingCreated);
    }, []);

    useEffect(() => {
        if (!speciesOptions || speciesOptions.length === 0) return;
        if (!classOptions || classOptions.length === 0) return;
        if (!managementPlanOptions || managementPlanOptions.length === 0) return;
        let initialAssignedPlans = [];

        if (consignment) {
            if (consignment.managementPlans && consignment.managementPlans.length > 0) {
                // get unique array of livestock species id
                let lUniqueSpeciesIDs = [...new Set(consignment.livestock.map(livestock => livestock.speciesId))];
                // filter out any management plans for species that thhis consignment no longer has
                let lFilteredPlans = [...consignment.managementPlans].filter(mp => lUniqueSpeciesIDs.includes(mp.speciesId));
                if (lFilteredPlans) {
                    lFilteredPlans.forEach((plan, index) => {
                        let lSelectedSpeciesAndClass = `${plan.speciesId},${plan.classId}`;
                        initialAssignedPlans.push({ 
                            id: index, 
                            numberOfHead: plan.numberOfHead, 
                            speciesOptions: speciesOptions, 
                            selectedSpecies: plan.speciesId, 
                            classOptions: classOptions, 
                            selectedClass: plan.classId, 
                            speciesAndClassOptions: speciesClassOptions, 
                            selectedSpeciesAndClass: lSelectedSpeciesAndClass, 
                            managementPlanOptions: managementPlanOptions, 
                            selectedManagementPlan: plan.managementPlanId 
                        });
                    })
                }
            }
        }

        // if no plans, addd efault empty row
        if (initialAssignedPlans.length === 0) {
            initialAssignedPlans = [
                { 
                    id: 0, 
                    numberOfHead: 0, 
                    speciesOptions: speciesOptions, 
                    selectedSpecies: null, 
                    classOptions: classOptions, 
                    selectedClass: null, 
                    speciesAndClassOptions: speciesClassOptions, 
                    managementPlanOptions: managementPlanOptions, 
                    selectedManagementPlan: null 
                }
            ];
        }

        setAssignedPlans(initialAssignedPlans)

    }, [speciesOptions, classOptions, managementPlanOptions, consignment])

    const goToReview = async () => {
        updateConsignmentBeingCreated();
        navigate("/createconsignmentreview", { state: { isLivestockChanged: location?.state?.isLivestockChanged }});
    }

    const goBack = () => {
        navigate("/createconsignmentlivestock");
    }

    const addNewAssignedManagementPlanRow = () => {
        let tempAssignedPlans = [...assignedPlans];
        tempAssignedPlans.push({ 
            id: tempAssignedPlans.length, 
            numberOfHead: 0, 
            speciesOptions: speciesOptions, 
            selectedSpecies: null, 
            classOptions: classOptions, 
            selectedClass: null, 
            speciesAndClassOptions: speciesClassOptions, 
            managementPlanOptions: managementPlanOptions, 
            selectedManagementPlan: null 
        });
        setAssignedPlans(tempAssignedPlans);
    }

    const removeAssignedManagementPlanRow = (planID) => {
        let tempAssignedPlans = [...assignedPlans];
        tempAssignedPlans = tempAssignedPlans.filter(p => p.id !== planID);
        setAssignedPlans(tempAssignedPlans);
    }

    const updateAssignedPlan = (id, selectedSpecies, selectedClass, selectedManagementPlan, numberOfHead) => {
        let tempAssignedPlans = [...assignedPlans];
        tempAssignedPlans = tempAssignedPlans.map(p => {
            if (Number(p.id) === Number(id)) {
                p.selectedSpecies = selectedSpecies;
                p.selectedClass = selectedClass;
                p.selectedManagementPlan = selectedManagementPlan;
                p.numberOfHead = numberOfHead;
            }
            return p;
        });
        setAssignedPlans(tempAssignedPlans);
    }

    const updateConsignmentBeingCreated = () => {
        // Only include management plans with a selcted species, management plan and number of head > 0
        let lValidAssignedPlans = [];
        if (assignedPlans && assignedPlans.length > 0) {
            [...assignedPlans].forEach(plan => {
                if (plan.selectedSpecies && plan.selectedClass && plan.selectedManagementPlan && plan.numberOfHead > 0) {
                    lValidAssignedPlans.push({
                        speciesId: plan.selectedSpecies,
                        classId: plan.selectedClass,
                        managementPlanId: plan.selectedManagementPlan,
                        numberOfHead: plan.numberOfHead
                    });
                }
            })
        }

        addToOrUpdateSessionConsignmentBeingCreated({
            managementPlans: lValidAssignedPlans
        })
    }

    const progressStages = [
        { title: "Voyage Details", completed: true },
        { title: "Personnel & Reporting", completed: true },
        { title: "Livestock", completed: true },
        { title: "Approved Management Plans", completed: false }
    ];

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>Create new consignment</div>
                                <ConsignmentProgression stages={progressStages} currentStage={progressStages[3]} key="consignment_progress" />
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Container className="py-4 px-5 form-container" >
                    <Row className="mb-2">
                        <Col xs={12}>
                            <div className="heading-text darkblue">Management plans</div>
                            <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col xs={12}>
                            <em>Please provide information about the management plans applicable to livestock on this voyage, and how many stock are covered under each plan.</em>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="field-label darkblue">
                            Species
                        </Col>
                        <Col xs={6} className="field-label darkblue">
                            Approved Management Plan
                        </Col>
                        <Col xs={2} className="field-label darkblue">
                            No. of Head
                        </Col>
                    </Row>

                    {
                        assignedPlans && assignedPlans.map((assPlan, i, arr) => {
                            return (
                                <ManagementPlanHeadCount key={`ass_plan_${assPlan.id}`} planData={assPlan} onDelete={removeAssignedManagementPlanRow} onChange={updateAssignedPlan} noCross={i === 0} />
                            )
                        })
                    }
                    <Row>
                        <Col>
                            <Button
                                className="mt-2 mb-4 cc-add-port-button px-2 py-1 border-0"
                                variant="secondary"
                                onClick={addNewAssignedManagementPlanRow}
                            >
                                <FontAwesomeIcon icon={faCirclePlus} className="primary-green" /> Add another management plan
                            </Button>
                        </Col>
                    </Row>
                    {showError ? (
                        <Row>
                            <Col xs={6}>
                                <Alert variant="danger">
                                    There was an unexpected error while trying to save the consignment.
                                </Alert>
                            </Col>
                        </Row>
                    ) : null}
                </Container>

                {showError ? (
                    <Row>
                        <Col xs={6}>
                            <Alert variant="danger">
                                There was an unexpected error while trying to save the consignment.
                            </Alert>
                        </Col>
                    </Row>
                ) : null}
                <Container className="button-container">
                    <Row className="mt-5" style={{ position: "relative", top: "-300px" }}>
                        <Stack direction="horizontal" gap={2}>
                            <Button variant="primary" onClick={goToReview} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>Complete</Button>
                            <Button className="me-2 me-auto" variant="secondary" onClick={goBack} size="lg" style={{ minWidth: "150px" }}>Back</Button>
                        </Stack>
                    </Row>
                </Container>
            </Container>

        </LoggedInContainer>
    )
};

export default Consignment_ManagementPlans;